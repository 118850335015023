module.exports = [{
      plugin: require('/Users/joshkowarsky/Documents/WebDev/FNiteStore/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-16831466-24"},
    },{
      plugin: require('/Users/joshkowarsky/Documents/WebDev/FNiteStore/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/joshkowarsky/Documents/WebDev/FNiteStore/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
